function Stock(props) {
  return (
    <>
      Available Sizes
      <br />
      <div className="">
        S&nbsp;|&nbsp;M&nbsp;|&nbsp;L&nbsp;|&nbsp;XL&nbsp;|&nbsp;2XL
      </div>
      <br />
      <strong>
        {props.discount === "-" ? null : (
          <>
            Was&nbsp;<span className="cancelled">{props.discount}</span>
            &nbsp;Now&nbsp;
          </>
        )}
        {props.price === "-" ? "SOLD OUT" : `${props.price}`}
      </strong>
      {props.price !== "-" && (
        <a
          href={`https://api.whatsapp.com/send?phone=+27815052318&text=${props.message}`}
          align="center"
          className="whatsappbutton d-flex justify-content-center align-items-center flex-nowrap mt-3 font-weight-bold px-3 py-2"
        >
          <i className="fa fa-whatsapp fa-2x"></i>
          <span>Order Now</span>
        </a>
      )}
    </>
  );
}

export default Stock;
