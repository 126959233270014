import srcLogo from "../assets/logo.svg";
import srcTee from "../assets/t-shirt.jpg";
import srcCap from "../assets/cap.webp";

import srcGreyTee from "../assets/grey-tee.jpg";
import srcBlackTee from "../assets/black-tee.jpg";
import srcWhiteTee from "../assets/white-tee.jpg";
import srcFunckyTee from "../assets/funky-tee.jpg";

export const logo = srcLogo;

export const images = {
  tee: srcTee,
  cap: srcCap,
  greyTee: srcGreyTee,
  blackTee: srcBlackTee,
  whiteTee: srcWhiteTee,
  funckyTee: srcFunckyTee,
};
