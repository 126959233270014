import Stock from "../components/Stock.js";
import { logo, images } from "../shared/Images.js";

function Home() {
  return (
    <div className="container-fluid">
      <div className="row pb-5">
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-start p-5">
          <div className="secondary-heading">
            Bespoke Couture
            <br />
            Est. MMXX
          </div>
          <div className="primary-heading py-4">
            <div className="sub-text">Lowco&nbsp;</div>
            <br />
            <div className="sub-text">Motion</div>
          </div>
          <div className="social-icons d-flex flex-row justify-content-between flex-wrap">
            <a className="social-url" target="_blank" href="#">
              <i className="fa fa-facebook"></i>
            </a>
            <a className="social-url px-5" target="_blank" href="#">
              <i className="fa fa-twitter"></i>
            </a>
            <a className="social-url" target="_blank" href="#">
              <i className="fa fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className="col-12 col-md-6 logo-wrapper py-4">
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={logo}
              className="img img-fluid img-logo"
              alt="site-logo"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pt-5">
          <div className="col-12 col-md-6 p-5">
            <div className="secondary-heading">
              Quality Mens
              <br />
              Fitted Tees
            </div>
            <p className="text-secondary mt-4">
              Soft Quality tees
              <br />
              Solid Colours: 160g / Cotton Lycra 95/5
              <br />
              Melange: 160g / Polycotton Lycra 62/33/5
            </p>
            <img
              src={images.tee}
              className="img img-fluid img-col"
              alt="quality-tee"
            />
          </div>
          <div className="col-12 col-md-6 p-5">
            <img
              src={images.cap}
              className="img img-fluid img-col"
              alt="quality-cap"
            />
            <div className="secondary-heading pt-5">
              Pop-A-Cap
              <br />
              In Yo Head
            </div>
            <p className="text-secondary mt-4">
              3d logo embroidery hip hop baseball caps
              <br />
              100% Cotton
              <br />
              Breathable
            </p>
          </div>
          <div className="col-12 pb-4">
            <div className="secondary-heading text-center">
              Related Products
            </div>
          </div>
          <div className="col-12 col-md-4">
            <img
              src={images.greyTee}
              className="img img-fluid img-product pb-4"
              alt="grey-tee"
            />
            <div className="secondary-heading">
              Melange Grey
              <br />
              Soft Tee
            </div>
            <p className="text-secondary mb-4">
              <Stock
                price="-"
                discount="-"
                message="Hello, may I please place an order for a Melange Grey T-Shirt."
              />
            </p>
          </div>
          <div className="col-12 col-md-4">
            <img
              src={images.whiteTee}
              className="img img-fluid img-product pb-4"
              alt="white-tee"
            />
            <div className="secondary-heading">
              Solid White
              <br />
              Soft Tee
            </div>
            <p className="text-secondary mb-4">
              <Stock
                price="-"
                discount="-"
                message="Hello, may I please place an order for a Solid White T-Shirt."
              />
            </p>
          </div>
          <div className="col-12 col-md-4">
            <img
              src={images.blackTee}
              className="img img-fluid img-product pb-4"
              alt="black-tee"
            />
            <div className="secondary-heading">
              Solid Black
              <br />
              Soft Tee
            </div>
            <p className="text-secondary mb-4">
              <Stock
                price="-"
                discount="-"
                message="Hello, may I please place an order for a Solid Black T-Shirt."
              />
            </p>
          </div>
          <div className="col-12 col-md-4">
            <img
              src={images.funckyTee}
              className="img img-fluid img-product pb-4"
              alt="junky-tee"
            />
            <div className="secondary-heading">
              Urban&nbsp;Green
              <br />
              Soft Tee
            </div>
            <p className="text-secondary mb-4">
              <Stock
                price="-"
                discount="-"
                message="Hello, may I please place an order for a Urban Green T-Shirt."
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
