import "./App.css";
import Home from "./pages/Home.js";

function App() {
  return (
    <>
      <Home />
      <div className="container-fluid mt-5 bg-purple">
        <div className="container text-white">
          <div className="row">
            <div className="col-12 d-flex flex-wrap justify-content-between align-items-center py-4 px-2">
              <div className="copyright">
                Copyright © Lowcomotion Clothing Co. {new Date().getFullYear()}{" "}
                All rights reserved.
              </div>
              <div className="privacy">
                <a className="text-white" href="#">
                  Terms and Conditions
                </a>
                &nbsp;|&nbsp;
                <a className="text-white" href="#">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
